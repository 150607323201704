@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tokens */
:root {
  --aside-width: 512px;
  --header-height: 64px;
  --color-light: #fff;
  --color-primary: 20 20 20; /* Text, buttons, etc. */
  --color-contrast: 250 250 249; /* Backgrounds, borders, etc. */
  --color-accent: 191 72 0; /* Labels like "On sale" */
  --color-dark: 43 45 53;
  --font-size-fine: 0.75rem; /* text-xs */
  --font-size-copy: 1rem; /* text-base */
  --font-size-lead: 1.125rem; /* text-lg */
  --font-size-heading: 2rem; /* text-2xl */
  --font-size-display: 3rem; /* text-4xl */
  --color-shop-pay: #5a31f4;
  --shop-pay-button--width: 100%; /* Sets the width for the shop-pay-button web component */
  --height-nav: 3rem;
  --screen-height: 100vh;
  --screen-height-dynamic: 100vh;

  @media (min-width: 640px) {
    --height-nav: 4rem;
  }
  @media (min-width: 1280px) {
    --height-nav: 6rem;
    --font-size-heading: 2.25rem; /* text-4xl */
    --font-size-display: 3.75rem; /* text-6xl */
  }
  @supports (height: 100svh) {
    --screen-height: 100svh;
  }
  @supports (height: 100dvh) {
    --screen-height-dynamic: 100dvh;
  }
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --color-primary: 250 250 250;
    --color-contrast: 32 33 36;
    --color-accent: 235 86 40;
  }
} */

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

shop-pay-button {
  width: 100%;
  height: 3rem;
  display: table;
}

@layer base {
  * {
    font-variant-ligatures: none;
  }

  body {
    @apply border-primary/10 bg-contrast text-primary/90 antialiased;
  }

  html {
    scroll-padding-top: 10rem;
  }

  model-viewer::part(default-progress-mask) {
    display: none;
  }

  model-viewer::part(default-progress-bar) {
    display: none;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  a.active {
    @apply border-b;
  }

  .prose {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:first-child {
        @apply mt-0;
      }
    }
  }
}

@layer components {
  .article {
    * {
      @apply font-sans !important;
    }
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply font-sans text-primary;
    }
    h1 {
      @apply text-brand
    }
    h2 {
      @apply text-brand text-xl lg:text-2xl font-bold;
    }
    h1, h2, h3, h4, h5, h6 {
      margin: 0 !important;
      margin-bottom: 10px !important;
    }
    p {
      margin: 0 !important;
      margin-bottom: 20px !important;
    }
    @apply prose w-full max-w-full mb-12 grid text-primary;
    table {
      @apply text-base;
      table {
        @apply bg-gray-100;
        td {
          @apply px-3 !text-base !text-primary;
        }
        td + td {
          @apply text-right;
        }
      }
    }
    td {
      vertical-align: middle !important;
    }
    p,
    ul,
    li {
      @apply mb-4 text-lg;
    }
    img {
      @apply md:-mx-8 lg:-mx-16;

      height: initial;
      width: initial;
      max-width: 80vw;
      max-height: 40vh;
      margin: 0 auto;
      display: inline-block;
    }
  }

  .swimlane {
    @apply grid w-full snap-x snap-mandatory scroll-px-6 grid-flow-col justify-start gap-4 overflow-x-scroll px-6 pb-4;
  }
}

@layer utilities {
  .fadeIn {
    opacity: 0;
    animation: fadeInAnimation ease 500ms forwards;
  }

  .hiddenScroll {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .strike {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 108%;
      height: 1.5px;
      left: -4%;
      top: 50%;
      transform: translateY(-50%);
      background: rgb(var(--color-primary));
      box-shadow: 0.5px 0.5px 0px 0.5px rgb(var(--color-contrast));
    }
  }

  .card-image {
    @apply relative flex items-center justify-center overflow-clip rounded;
    &::before {
      content: ' ';
      @apply absolute left-0 top-0 z-10 block h-full w-full rounded shadow-border;
    }
    img {
      @apply aspect-[inherit] w-full object-contain;
    }
  }

  .flex-break {
    flex-basis: 100%;
    height: 0;
  }
}

.pdp-embla-main {
  --slide-size: 100%;
}

.pdp-embla__slide {
  flex: 0 0 var(--slide-size);
}

.parallelogram {
  position: relative;
}

.small-parallelogram::before {
  border-right: 8px solid transparent;
  border-top: 20px solid #fff;
  left: 0;
}

.small-parallelogram::after {
  border-left: 8px solid transparent;
  border-bottom: 20px solid #fff;
  right: 0;
}


.tab-parallelogram::before {
  border-right: 20px solid transparent;
  border-top: 44px solid #fff;
  left: 0;
}

.tab-parallelogram::after {
  border-bottom: 44px solid #fff;
  border-left: 20px solid transparent;
  right: 0;
}

.tab-parallelogram:first-child::before {
  border: none;
}

.search__clear-icon svg {
  @apply w-full;
}

.contact-field {
  @apply col-start-1 col-end-3 border border-[#707070] px-4 py-3;
}

input.no-spin::-webkit-outer-spin-button,
input.no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'].no-spin {
  -moz-appearance: textfield;
}


.nav-bg-left::before {
  content: "";
  @apply bg-brand absolute -top-2 -left-3 z-10 w-6 h-20;
  transform: rotate(20deg);
}
.nav-bg-right::before {
  content: "";
  @apply bg-dark absolute -top-2 -left-3 z-10 w-6 h-20;
  transform: rotate(20deg);
}

.pdp-thumbs__selected button {
  border-color: black;
}

/* honeypot form fields */
.xb8ddd9536d9a40313658b1d9c9f8536305a34491479b7a05be14f2514b1cac96,
.x273701390ef57a572f089f30ae1314281007fdcbed8949e68573788739303163,
.x273701390ef57a572f089f30ae1314281007fdcbed8949e68573788739303163,
.x373701390ef57a572f089f30ae1314281007fdcbed8949e68573788739303163,
.xa8ddd9536d9a40313658b1d9c9f8536305a34491479b7a05be14f2514b1cac96,
.x373701390ef57a572f089f30ae1314281007fdcbed8949e68573788739303163,
.x473701390ef57a572f089f30ae1314281007fdcbed8949e68573788739303163,
.x573701390ef57a572f089f30ae1314281007fdcbed8949e68573788739303163,
.x373701390ef57a572f089sad8w9h84281007fdcbed8949e68573788739303163,
.x373701390ef57a509hjk0ggh9s07sdf81007fdcbed8949e68573788739303163
{
  @apply opacity-0 absolute top-0 left-0 w-0 h-0 -z-10;
}

.submit-button {
  @apply bg-dark relative text-white h-12 px-8 py-2 text-base uppercase transition hover:bg-brand xl:text-xl tracking-widest font-medium;
}
.submit-button::before {
  content: "";
  @apply absolute top-0 right-full border-b-dark transition;
  border-bottom-width: 48px;
  border-left: 20px solid transparent;
}
.submit-button::after {
  content: "";
  @apply absolute top-0 left-full border-t-dark transition;
  border-top-width: 48px;
  border-right: 20px solid transparent;
}
.submit-button:hover {
  &::before {
    @apply border-b-brand;
  }
  &::after {
    @apply border-t-brand;
  }
}

.btn-brand {
  @apply bg-brand hover:bg-dark;
  &::before {
    @apply border-b-brand;
  }
  &::after {
    @apply border-t-brand;
  }
  &:hover {
    &::before {
      @apply border-b-dark;
    }
    &::after {
      @apply border-t-dark;
    }
  }
}

.btn-hans-schreiner {
  @apply hover:bg-dark;
  background: #254D9C;
  &::before {
    border-bottom-color: #254D9C;
  }
  &::after {
    border-top-color: #254D9C;
  }
  &:hover {
    &::before {
      @apply border-b-dark;
    }
    &::after {
      @apply border-t-dark;
    }
  }
}

.btn-hauslhof {
  @apply text-black hover:bg-dark;
  background: #fbc317;
  &::before {
    border-bottom-color: #fbc317;
  }
  &::after {
    border-top-color: #fbc317;
  }
  &:hover {
    @apply text-white;
    &::before {
      @apply border-b-dark;
    }
    &::after {
      @apply border-t-dark;
    }
  }
}

.btn-forestor-pilous {
  @apply hover:bg-dark;
  background: #E20714;
  &::before {
    border-bottom-color: #E20714;
  }
  &::after {
    border-top-color: #E20714;
  }
  &:hover {
    &::before {
      @apply border-b-dark;
    }
    &::after {
      @apply border-t-dark;
    }
  }
}


/*
* --------------------------------------------------
* components/Aside
* --------------------------------------------------
*/
aside {
  background: var(--color-light);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
  height: calc(var(--header-height) * -1 + 100vh);
  max-width: var(--aside-width);
  min-width: var(--aside-width);
  position: fixed;
  right: calc(-1 * var(--aside-width));
  top: var(--header-height);
  transition: transform 200ms ease-in-out;
}

aside header {
  align-items: center;
  border-bottom: 1px solid var(--color-dark);
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  padding: 0 20px;
}

aside header h3 {
  margin: 0;
}

aside header .close {
  font-weight: bold;
  opacity: 0.8;
  text-decoration: none;
  transition: all 200ms;
  width: 20px;
}

aside header .close:hover {
  opacity: 1;
}

aside header h2 {
  margin-bottom: 0.6rem;
  margin-top: 0;
}

aside main {
  margin: 1rem;
}

aside p {
  margin: 0 0 0.25rem;
}

aside p:last-child {
  margin: 0;
}

aside li {
  margin-bottom: 0.125rem;
}

.overlay {
  background: rgba(0, 0, 0, 0.2);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in-out;
  transition: opacity 400ms;
  visibility: hidden;
  z-index: 50;
}

.overlay .close-outside {
  background: transparent;
  border: none;
  color: transparent;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: calc(100% - var(--aside-width));
}

.overlay .light {
  background: rgba(255, 255, 255, 0.5);
}

.overlay .cancel {
  cursor: default;
  height: 100%;
  position: absolute;
  width: 100%;
}

.overlay.expanded {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}
/* reveal aside */
.overlay.expanded aside {
  transform: translateX(calc(var(--aside-width) * -1));
}


/*pop-up styles*/


@font-face {
  font-display: block;
  font-family: Roboto;
  src: url(https://assets.brevo.com/font/Roboto/Latin/normal/normal/7529907e9eaf8ebb5220c5f9850e3811.woff2) format("woff2"), url(https://assets.brevo.com/font/Roboto/Latin/normal/normal/25c678feafdc175a70922a116c9be3e7.woff) format("woff")
}

@font-face {
  font-display: fallback;
  font-family: Roboto;
  font-weight: 600;
  src: url(https://assets.brevo.com/font/Roboto/Latin/medium/normal/6e9caeeafb1f3491be3e32744bc30440.woff2) format("woff2"), url(https://assets.brevo.com/font/Roboto/Latin/medium/normal/71501f0d8d5aa95960f6475d5487d4c2.woff) format("woff")
}

@font-face {
  font-display: fallback;
  font-family: Roboto;
  font-weight: 700;
  src: url(https://assets.brevo.com/font/Roboto/Latin/bold/normal/3ef7cf158f310cf752d5ad08cd0e7e60.woff2) format("woff2"), url(https://assets.brevo.com/font/Roboto/Latin/bold/normal/ece3a1d82f18b60bcce0211725c476aa.woff) format("woff")
}

#sib-container input:-ms-input-placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #c0ccda;
}

#sib-container input::placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #c0ccda;
}

#sib-container textarea::placeholder {
  text-align: left;
  font-family: "Helvetica", sans-serif;
  color: #c0ccda;
}

#sib-container a {
  text-decoration: underline;
  color: #2BB2FC;
}

.sr-only {
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  white-space: nowrap;
}
